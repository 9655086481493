import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class TitleService {

  constructor(
    private readonly browserTitle: Title,
  ) {}

  get title(): string {
    return this.browserTitle.getTitle();
  }

  set title(title: string) {
    this.browserTitle.setTitle('Claim Watcher - ' + title);
  }

}
