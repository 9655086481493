import {
  ChangeDetectionStrategy, Component, OnInit, ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroupDirective, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService, NoticeService, TitleService } from '../shared/services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login-shared.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly formBuilder: FormBuilder,
    private readonly noticeService: NoticeService,
    private readonly router: Router,
    private readonly titleService: TitleService,
  ) {}

  @ViewChild(FormGroupDirective, { static: true })
  ngForm!: FormGroupDirective;

  readonly form = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', Validators.required],
  });

  ngOnInit(): void {
    this.titleService.title = 'Log In';
  }

  onSubmit(): void {
    if (this.form.invalid) {
      this.noticeService.warning('Please fill in the required fields.');
      return;
    }

    const value = this.form.value;

    this.form.disable();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.authService.login(value.email!, value.password!).subscribe(
      () => {
        const redirect = this.activatedRoute.snapshot.queryParamMap.get('redirect');
        if (redirect === null) {
          this.router.navigateByUrl('/');
        } else {
          this.router.navigateByUrl(redirect);
        }
      },
      err => {
        console.error('failed to log in:', err);

        let msg = 'Failed to log in';
        if (err.error && err.error.message) {
          msg = `${msg}: ${err.error.message}`;
        }

        this.noticeService.error(msg);

        this.ngForm.resetForm();
        this.form.enable();
        this.form.reset({
          ...value,
          password: '',
        });
      },
    );
  }

}
