import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import {
  AuthState, AuthStateLoading, AuthStateLoggedIn, AuthStateLoggedOut,
} from '../models';

export function authStateNotLoading(
  state: AuthState): state is (AuthStateLoggedIn | AuthStateLoggedOut) {
  return state.type !== 'loading';
}

export function authStateLoading(
  state: AuthState): state is AuthStateLoading {
  return state.type === 'loading';
}

export function authStateLoggedOut(
  state: AuthState): state is AuthStateLoggedOut {
  return state.type === 'logged-out';
}

export function authStateLoggedIn(
  state: AuthState): state is AuthStateLoggedIn {
  return state.type === 'logged-in';
}

export function filterAuthStateNotLoading(
  source: Observable<AuthState>): Observable<AuthStateLoggedIn | AuthStateLoggedOut> {
  return source.pipe(filter(authStateNotLoading));
}

export function filterAuthStateLoading(
  source: Observable<AuthState>): Observable<AuthStateLoading> {
  return source.pipe(filter(authStateLoading));
}

export function filterAuthStateLoggedOut(
  source: Observable<AuthState>): Observable<AuthStateLoggedOut> {
  return source.pipe(filter(authStateLoggedOut));
}

export function filterAuthStateLoggedIn(
  source: Observable<AuthState>): Observable<AuthStateLoggedIn> {
  return source.pipe(filter(authStateLoggedIn));
}
