import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import {
  ComponentPortal, ComponentType, PortalInjector,
} from '@angular/cdk/portal';
import { ComponentFactoryResolver, Injectable, Injector } from '@angular/core';

import { ModalRef } from '../utils/modal-ref';

import {
  DOCUMENT_MODAL_FILETYPE_TOKEN, DOCUMENT_MODAL_URL_TOKEN,
  DocumentModalComponent,
} from '../components/document-modal/document-modal.component';
import {
  FORM_MODAL_OPTIONS_TOKEN, FormModalComponent, FormModalOptions,
} from '../components/form-modal/form-modal.component';
import {
  MESSAGE_MODAL_OPTIONS_TOKEN, MessageModalComponent, MessageModalOptions,
} from '../components/message-modal/message-modal.component';

@Injectable({ providedIn: 'root' })
export class ModalService {

  constructor(
    private readonly injector: Injector,
    private readonly overlay: Overlay,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
  ) {}

  message(options: MessageModalOptions): ModalRef {
    const config: OverlayConfig = {
      positionStrategy: this.overlay.position()
        .global()
        .top('30px')
        .centerHorizontally(),
    };

    const tokens = new WeakMap();
    tokens.set(MESSAGE_MODAL_OPTIONS_TOKEN, options);

    return this.makeOverlay(config, tokens, MessageModalComponent,
      this.componentFactoryResolver);
  }

  form(options: FormModalOptions): ModalRef {
    const config: OverlayConfig = {
      positionStrategy: this.overlay.position()
        .global()
        .top('30px')
        .centerHorizontally(),
    };

    const tokens = new WeakMap();
    tokens.set(FORM_MODAL_OPTIONS_TOKEN, options);

    return this.makeOverlay(config, tokens, FormModalComponent,
      this.componentFactoryResolver);
  }

  document(url: string, fileType = ''): ModalRef {
    const config: OverlayConfig = {
      positionStrategy: this.overlay.position()
        .global()
        .top('30px')
        .centerHorizontally()
        .centerVertically(),
    };

    const tokens = new WeakMap();
    tokens.set(DOCUMENT_MODAL_URL_TOKEN, url);
    tokens.set(DOCUMENT_MODAL_FILETYPE_TOKEN, fileType);

    return this.makeOverlay(config, tokens, DocumentModalComponent,
      this.componentFactoryResolver);
  }

  makeOverlay<T>(config: OverlayConfig, tokens: WeakMap<any, any>,
                 component: ComponentType<T>, componentFactoryResolver: ComponentFactoryResolver): ModalRef {
    const overlayRef = this.overlay.create({
      hasBackdrop: true,
      disposeOnNavigation: true,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      ...config,
    });

    const modalRef = new ModalRef(overlayRef);
    tokens.set(ModalRef, modalRef);

    const injector = new PortalInjector(this.injector, tokens);

    const resolver = componentFactoryResolver ?
      componentFactoryResolver : this.componentFactoryResolver;

    overlayRef.attach(new ComponentPortal(
      component, null, injector, resolver));

    return modalRef;
  }

}
