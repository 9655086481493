<form [formGroup]="form" (ngSubmit)="onSubmit()" #ngForm="ngForm"
    class="well" [class.ng-submitted]="ngForm.submitted">
  <header>
    <h2>Set a New Password</h2>
  </header>

  <label>
    Password
    <input formControlName="password" type="password"
        autocomplete="new-password" class="form-control">
  </label>

  <label>
    Password Confirmation
    <input formControlName="confirm" type="password"
        autocomplete="new-password" class="form-control">
    <app-input-error name="confirm">
      <ng-template appErrorMessage="required"></ng-template>
      <ng-template appErrorMessage="match">Passwords must match</ng-template>
    </app-input-error>
  </label>

  <div class="verification">
    <span class="length-status">
      <div [ngClass]="passwordLengthValidate(form.value.password?.length) ? 
                    'valid-icon glyphicon glyphicon-ok' :
                    'invalid-icon glyphicon glyphicon-remove'"> 
      </div>
      <label>Minimum 12 characters ({{form.value.password?.length}})</label>
    </span>
  
    <span class="lettercase-statuses">
      <div class="uppercase-status">
        <div [ngClass]="passwordLettercaseValidate(form.value.password, 'uppercase') ? 
                       'valid-icon glyphicon glyphicon-ok' :
                       'invalid-icon glyphicon glyphicon-remove'"> 
        </div>
        <label>One uppercase letter</label>   
      </div>
      <div class="lowercase-status">
        <div [ngClass]="passwordLettercaseValidate(form.value.password, 'lowercase') ? 
                       'valid-icon glyphicon glyphicon-ok' :
                       'invalid-icon glyphicon glyphicon-remove'">  
        </div>
        <label>One lowercase letter</label>
      </div>
    </span>
  </div>

  <button type="submit" [disabled]="form.disabled" class="button submit-button">
    Submit
  </button>
</form>
