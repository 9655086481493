import { ConnectableObservable, Observable } from 'rxjs';

export function isConnectable<T>(source: Observable<T>): source is ConnectableObservable<T> {
  return 'connect' in source;
}

export function autoConnect<T>(source: Observable<T>): Observable<T> {
  if (!isConnectable(source)) {
    throw new Error('source not connectable');
  }

  source.connect();
  return source;
}
