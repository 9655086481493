import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { first, publishReplay } from 'rxjs/operators';

import { configMapper } from '../mappings';
import { Config } from '../models';
import { autoConnect } from '../utils/rxjs';

const CONFIG_PATH = '/assets/config.json';

@Injectable({ providedIn: 'root' })
export class ConfigService {

  constructor(
    private readonly httpClient: HttpClient,
  ) {}

  readonly config$: Observable<Config> = this.httpClient
    .get(CONFIG_PATH)
    .pipe(
      configMapper.mapDeserialize,
      publishReplay(1),
      autoConnect,
      first(),
    );

}
