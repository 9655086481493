import {
  AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Inject,
  InjectionToken, OnDestroy, ViewChild,
} from '@angular/core';

import { embed } from 'pdfobject';

import { ModalRef } from '../../utils/modal-ref';

export const DOCUMENT_MODAL_URL_TOKEN = new InjectionToken<string>('DocumentModalComponent.url');
export const DOCUMENT_MODAL_FILETYPE_TOKEN = new InjectionToken<string>('DocumentModalComponent.fileType');

@Component({
  selector: 'app-image-modal',
  templateUrl: './document-modal.component.html',
  styleUrls: ['./document-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentModalComponent implements OnDestroy, AfterViewInit {

  constructor(
    public readonly modalRef: ModalRef,
    @Inject(DOCUMENT_MODAL_URL_TOKEN) public readonly url: string,
    @Inject(DOCUMENT_MODAL_FILETYPE_TOKEN) public readonly fileType: string,
  ) {}

  @ViewChild('pdfContainer') pdfContainer?: ElementRef<HTMLElement>;

  loading = this.fileType !== 'pdf';

  private readonly backdropSub = this.modalRef.backdropClick$
    .subscribe(() => this.modalRef.close());

  ngOnDestroy(): void {
    this.backdropSub.unsubscribe();
  }

  ngAfterViewInit(): void {
    if (this.pdfContainer) {
      embed(this.url, this.pdfContainer.nativeElement);
    }
  }

}
