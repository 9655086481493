import {
  ChangeDetectionStrategy, Component, Inject, InjectionToken, OnDestroy,
} from '@angular/core';

import { ModalRef } from '../../utils/modal-ref';

export interface MessageModalOptions {
  title: string;
  message: string;

  okText?: string;
  cancelText?: string;
}

export const MESSAGE_MODAL_OPTIONS_TOKEN = new InjectionToken<MessageModalOptions>('MessageModalComponent.options');

@Component({
  selector: 'app-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageModalComponent implements OnDestroy {

  constructor(
    public readonly modalRef: ModalRef,
    @Inject(MESSAGE_MODAL_OPTIONS_TOKEN) public readonly options: MessageModalOptions,
  ) {}

  private readonly backdropSub = this.modalRef.backdropClick$
    .subscribe(() => this.modalRef.close());

  ngOnDestroy(): void {
    this.backdropSub.unsubscribe();
  }

}
