import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadingService } from '../services';


@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

  constructor(
    private readonly injector: Injector,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const loadingService = this.injector.get(LoadingService);
    loadingService.start();
    return next.handle(req).pipe(
      finalize(() => { loadingService.stop(); })
    );
  }
}
