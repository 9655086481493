import {
  ChangeDetectionStrategy, Component, ContentChildren, Input, OnChanges,
  QueryList, SimpleChanges,
} from '@angular/core';
import { FormGroupDirective } from '@angular/forms';

import { combineLatest, EMPTY, Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';

import { ErrorMessageDirective } from '../../directives';

export interface InputErrorContext {
  type: string;
  error: any;
}

@Component({
  selector: 'app-input-error',
  templateUrl: './input-error.component.html',
  styleUrls: ['./input-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputErrorComponent implements OnChanges {

  constructor(
    private readonly formGroupDirective: FormGroupDirective,
  ) {}

  @Input() name = '';
  @Input() label = 'This field';

  @ContentChildren(ErrorMessageDirective)
  customMessages?: QueryList<ErrorMessageDirective>;

  error$?: Observable<InputErrorContext | null>;
  customMessage?: ErrorMessageDirective;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.name) {
      this.error$ = this.getError();
    }
  }

  private getError(): Observable<InputErrorContext | null> {
    const control = this.formGroupDirective.form.get(this.name);

    if (!control) {
      return EMPTY;
    }

    return combineLatest([
      this.formGroupDirective.ngSubmit,
      control.statusChanges.pipe(startWith(control.status)),
    ]).pipe(
      map(([_, status]) => {
        if (!this.formGroupDirective.submitted && control.untouched) {
          return null;
        }

        switch (status) {
          case 'INVALID':
            if (control.errors) {
              control.markAsTouched();
              const entry = Object.entries(control.errors)[0];
              return {
                type: entry[0],
                error: entry[1],
              };
            }
            // falls through

          default:
            return null;
        }
      }),
      tap(error => {
        if (!this.customMessages || !error) {
          delete this.customMessage;
          return;
        }
        this.customMessage = this.customMessages.find(m => m.type === error.type);
      }),
    );
  }

}
