import {
  AbstractControl, ValidationErrors, ValidatorFn, Validators,
} from '@angular/forms';

export function conditionalRequire(field: string, isRequired: (form: AbstractControl) => boolean): ValidatorFn {
  return form => {
    const fieldControl = form.get(field);
    if (!fieldControl) {
      throw new Error(`no field found with name: ${field}`);
    }

    let err = null;

    if (isRequired(form)) {
      err = Validators.required(fieldControl);
    }

    if (err) {
      addError(fieldControl, err);
    } else {
      removeError(fieldControl, 'required');
    }

    return null;
  };
}

export function requireMatch(source: string, confirm: string): ValidatorFn {
  return form => {
    const sourceControl = form.get(source);
    if (!sourceControl) {
      throw new Error(`source control not found: ${source}`);
    }

    const confirmControl = form.get(confirm);
    if (!confirmControl) {
      throw new Error(`source control not found: ${confirm}`);
    }

    if (sourceControl.value !== confirmControl.value) {
      addError(confirmControl, { match: true });
    } else {
      removeError(confirmControl, 'match');
    }

    return null;
  };
}

export function conditionalNonZero(field: string, isRequired: (form: AbstractControl) => boolean): ValidatorFn {
  return form => {
    const fieldControl = form.get(field);
    if (!fieldControl) {
      throw new Error(`no field found with name: ${field}`);
    }

    let err = null;

    if (isRequired(form)) {
      err = nonZero(fieldControl);
    }

    if (err) {
      addError(fieldControl, err);
    } else {
      removeError(fieldControl, 'nonZero');
    }

    return null;
  };
}

export function nonZero(control: AbstractControl): ValidationErrors | null {
  if (control.value !== 0) {
    return null;
  }

  return { nonZero: true };
}

export function addError(control: AbstractControl, error: ValidationErrors): void {
  setError(control, { ...control.errors, ...error });
}

export function removeError(control: AbstractControl, key: string): void {
  const errors: ValidationErrors = { ...control.errors };
  delete errors[key];
  setError(control, errors);
}

export function setError(control: AbstractControl, errors: ValidationErrors): void {
  if (Object.keys(errors).length === 0) {
    control.setErrors(null);
  } else {
    control.setErrors(errors);
  }
}
