<form [formGroup]="form" (ngSubmit)="onSubmit()" #ngForm="ngForm"
    class="well" [class.ng-submitted]="ngForm.submitted">
  <header>
    <h2>Forgot Password?</h2>
  </header>

  <p>Enter your email address and we will send you instructions on how to
  reset your password.</p>

  <label>
    Email
    <input formControlName="email" type="email"
        autocomplete="email" class="form-control">
    <app-input-error name="email"></app-input-error>
  </label>

  <button type="submit" [disabled]="form.disabled" class="button submit-button">
    Submit
  </button>
</form>
