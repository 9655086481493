import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({ selector: '[appErrorMessage]' })
export class ErrorMessageDirective {

  constructor(
    public readonly templateRef: TemplateRef<any>,
  ) {}

  @Input('appErrorMessage') type = '';

}
