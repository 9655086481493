<form [formGroup]="form" (ngSubmit)="onSubmit()" #ngForm="ngForm"
    class="well" [class.ng-submitted]="ngForm.submitted">

  <header>
    <h1>Welcome!</h1>
    <h3>Please log in</h3>
  </header>

  <label>
    Email
    <input formControlName="email" type="email"
        autocomplete="email" class="form-control">
    <app-input-error name="email"></app-input-error>
  </label>

  <label>
    Password
    <input formControlName="password" type="password"
        autocomplete="current-password" class="form-control">
    <app-input-error name="password"></app-input-error>
  </label>

  <a routerLink="/forgot-password" class="forgot">Forgot Password?</a>

  <button type="submit" [disabled]="form.disabled"
      class="button submit-button">
    Log In
  </button>

</form>
