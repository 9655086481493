import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoggedOutGuard } from '../shared/guards';

import { ForgotComponent } from './forgot.component';
import { LoginComponent } from './login.component';
import { ResetComponent } from './reset.component';
import { ShellComponent } from './shell.component';

const ROUTES: Routes = [
  {
    path: '',
    component: ShellComponent,
    canActivate: [LoggedOutGuard],
    canActivateChild: [LoggedOutGuard],
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'forgot-password', component: ForgotComponent },
      { path: 'reset-password', component: ResetComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  exports: [RouterModule],
})
export class LoginRoutingModule {}
