import {
  HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { Observable, zip } from 'rxjs';
import { first, switchMap, tap } from 'rxjs/operators';

import { 
  AuthService, 
  ConfigService, 
  NoticeService 
} from '../services';

const API_PREFIX = '/api/';
const HEADER_AUTH = 'Authorization';
const BEARER_PREFIX = 'Bearer ';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private readonly injector: Injector,
    private readonly authService: AuthService,
    private readonly noticeService: NoticeService,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.startsWith(API_PREFIX)) {
      return next.handle(req);
    }

    const { config$ } = this.injector.get(ConfigService);

    return zip(config$, this.authService.state$).pipe(
      first(),
      switchMap(([config, authState]) => {
        let headers = req.headers;

        // Attach auth token if present.
        if (authState.type === 'logged-in') {
          const authHeader = BEARER_PREFIX + authState.token;
          headers = headers.set(HEADER_AUTH, authHeader);
        }

        // Rewrite url to configured API url.
        const url = req.url.replace(API_PREFIX, config.apiUrl);

        return next.handle(req.clone({ url, headers }));
      }),
      tap({
        error: err => {
          if (!(err instanceof HttpErrorResponse) || err.status !== 401) {
            return;
          }

          // Filter out a few endpoints to not log out on 401's
          if (err.url) {
            if (err.url.endsWith('/authorizations')) {
              return;
            }
            if (err.url.endsWith('/authorizations/logout')) {
              return;
            }
          }

          this.noticeService.warning('Your session has expired.');
          this.authService.clear();
        },
      }),
    );
  }

}
