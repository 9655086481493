import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { catchError, filter, finalize, mapTo, tap } from 'rxjs/operators';

import { AuthState } from '../models';

const URL_LOGIN = '/api/authorizations';
const URL_LOGOUT = `${URL_LOGIN}/logout`;
const URL_SEND_RESET = `${URL_LOGIN}/send_reset_password`;
const URL_SET_PASSWORD = `${URL_LOGIN}/set_password`;

const STORAGE_KEY = 'claimwatcher-auth';

@Injectable({ providedIn: 'root' })
export class AuthService implements OnDestroy {

  constructor(
    private readonly httpClient: HttpClient,
    private readonly router: Router,
  ) {
    this.loadToken();
  }

  private readonly stateSubject =
    new BehaviorSubject<AuthState>({ type: 'loading' });

  private readonly storageSub = fromEvent<StorageEvent>(window, 'storage')
    .pipe(filter(event => event.key === STORAGE_KEY))
    .subscribe(event => {
      if (event.newValue === null || event.newValue.length === 0) {
        this.setLoggedOut();
        return;
      }
      this.setLoggedIn(event.newValue);
    });

  readonly state$ = this.stateSubject.asObservable();

  get state(): AuthState {
    return this.stateSubject.value;
  }

  ngOnDestroy(): void {
    this.storageSub.unsubscribe();
  }

  login(email: string, password: string): Observable<void> {
    return this.httpClient
      .post<{ token: string }>(URL_LOGIN, { email, password })
      .pipe(
        tap(({ token }) => {
          if (typeof token !== 'string' || token.length === 0) {
            throw new Error('Invalid token');
          }
          this.setLoggedIn(token);
          this.saveToken(token);
        }),
        catchError(err => {
          this.setLoggedOut();
          this.stateSubject.next({ type: 'logged-out' });
          throw err;
        }),
        mapTo(undefined),
      );
  }

  logout(): Observable<void> {
    return this.httpClient.delete<void>(URL_LOGOUT)
      .pipe(finalize(() => this.clear()));
  }

  sendPasswordReset(email: string): Observable<void> {
    return this.httpClient.put<void>(URL_SEND_RESET, { email });
  }

  setPassword(token: string, password: string): Observable<void> {
    return this.httpClient.put<void>(URL_SET_PASSWORD, {
      password_reset_token: token,
      password,
      password_confirmation: password,
    });
  }

  clear(): void {
    this.removeToken();
    this.setLoggedOut();

    const redirect = this.router.url;
    this.router.navigate(['/login'], {
      queryParams: {
        redirect
      }
    });
  }

  private setLoggedOut(): void {
    this.stateSubject.next({ type: 'logged-out' });
  }

  private setLoggedIn(token: string): void {
    this.stateSubject.next({ type: 'logged-in', token });
  }

  private validateToken(token: unknown): token is string {
    return typeof token === 'string' && token !== null && token.length > 0;
  }

  private loadToken(): void {
    const token = localStorage.getItem(STORAGE_KEY);
    if (this.validateToken(token)) {
      this.setLoggedIn(token);
    } else {
      this.setLoggedOut();
    }
  }

  private saveToken(token: string): void {
    localStorage.setItem(STORAGE_KEY, token);
  }

  private removeToken(): void {
    localStorage.removeItem(STORAGE_KEY);
  }

}
