import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { ForgotComponent } from './forgot.component';
import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';
import { ResetComponent } from './reset.component';
import { ShellComponent } from './shell.component';

@NgModule({
  imports: [
    SharedModule,

    LoginRoutingModule,
  ],
  declarations: [LoginComponent, ForgotComponent, ShellComponent, ResetComponent],
})
export class LoginModule {}
