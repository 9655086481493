import {
  ChangeDetectionStrategy, Component, OnInit, ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroupDirective, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService, NoticeService, TitleService } from '../shared/services';

@Component({
  selector: 'app-login-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./login-shared.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotComponent implements OnInit {

  constructor(
    private readonly authService: AuthService,
    private readonly formBuilder: FormBuilder,
    private readonly noticeService: NoticeService,
    private readonly router: Router,
    private readonly titleService: TitleService,
  ) { }

  @ViewChild(FormGroupDirective, { static: true })
  ngForm!: FormGroupDirective;

  readonly form = this.formBuilder.group(
    { email: ['', [Validators.required, Validators.email]], }
  );

  ngOnInit(): void {
    this.titleService.title = 'Forgot Password';
  }

  onSubmit(): void {
    if (this.form.invalid) {
      this.noticeService.warning('Please fill in the required fields.');
      return;
    }

    const value = this.form.value;

    this.form.disable();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.authService.sendPasswordReset(value.email!).subscribe(
      () => {
        this.noticeService.info('Check your email for a link to reset your password.');
        this.router.navigate(['/login']);
      },
      err => {
        console.error('failed to send password reset email:', err);
        this.noticeService.error(
          'Failed to send password reset email, please try again later.');

        this.ngForm.resetForm({ ...value });
        this.form.enable();
      },
    );
  }

}
