import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LoadingService } from '../../services';

@Component({
  selector: 'app-auto-spinner',
  templateUrl: './auto-spinner.component.html',
  styleUrls: ['./auto-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutoSpinnerComponent {
  constructor(
    readonly loadingService: LoadingService
  ) { }

  @Input() message = 'Loading...';

}