import {
  ChangeDetectionStrategy, Component, Inject, InjectionToken, OnDestroy,
} from '@angular/core';
import { UntypedFormBuilder, FormGroup, ValidatorFn } from '@angular/forms';

import { ModalRef } from '../../utils/modal-ref';

export interface FormModalOptions {
  title: string;
  message?: string;
  fields: Array<{
    name: string;
    type: 'text' | 'number';
    label: string;
    validators?: ValidatorFn[];
  }>;
}

export const FORM_MODAL_OPTIONS_TOKEN = new InjectionToken<string>('FormModalComponent.options');

@Component({
  selector: 'app-form-modal',
  templateUrl: './form-modal.component.html',
  styleUrls: ['./form-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormModalComponent implements OnDestroy {

  constructor(
    public readonly modalRef: ModalRef,
    @Inject(FORM_MODAL_OPTIONS_TOKEN) public readonly options: FormModalOptions,
    formBuilder: UntypedFormBuilder,
  ) {
    const config: { [key: string]: any } = {};
    for (const field of options.fields) {
      config[field.name] = [null, field.validators];
    }

    this.form = formBuilder.group(config);
  }

  readonly form: FormGroup;

  private readonly backdropSub = this.modalRef.backdropClick$
    .subscribe(() => this.modalRef.close());

  ngOnDestroy(): void {
    this.backdropSub.unsubscribe();
  }

  onSubmit(): void {
    if (this.form.invalid) {
      console.warn('form invalid');
      return;
    }

    this.modalRef.close(this.form.value);
  }

}
