import {
  ChangeDetectionStrategy, Component, OnInit, ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroupDirective, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService, NoticeService, TitleService } from '../shared/services';
import { requireMatch } from '../shared/utils/validators';

const PASSWORD_LETTERCASE_VALIDATION = Validators.pattern(/(?=.*[a-z])(?=.*[A-Z]).*/);

@Component({
  selector: 'app-login-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./login-shared.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetComponent implements OnInit {

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly formBuilder: FormBuilder,
    private readonly noticeService: NoticeService,
    private readonly router: Router,
    private readonly titleService: TitleService,
  ) { }

  private readonly minimumPasswordLength = 12;

  @ViewChild(FormGroupDirective, { static: true })
  ngForm!: FormGroupDirective;

  readonly form = this.formBuilder.group({
    password: ['', [
      Validators.required, 
      Validators.minLength(this.minimumPasswordLength), 
      PASSWORD_LETTERCASE_VALIDATION
    ]],
    confirm: ['', Validators.required],
  }, { validators: requireMatch('password', 'confirm'), });

  ngOnInit(): void {
    if (!this.activatedRoute.snapshot.queryParamMap.has('token')) {
      console.warn('no token to reset password');
      this.router.navigate(['/login']);
      return;
    }

    this.titleService.title = 'Reset Password';
  }

  onSubmit(): void {
    if (this.form.invalid) {
      this.noticeService.warning('Password is invalid.');
      return;
    }

    const token = this.activatedRoute.snapshot.queryParamMap.get('token');
    if (token === null) {
      console.warn('no token to reset password');
      return;
    }

    const value = this.form.value;

    this.form.disable();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.authService.setPassword(token, value.password!).subscribe(
      () => {
        this.noticeService.info('Password reset successfully.');
        this.router.navigate(['/login']);
      },
      err => {
        let errorMessage = 'failed to reset password.';
        if (err.error.message == 'Invalid password') {
          errorMessage = 'Password is invalid.';
        }
        console.error('failed to reset password:', err);
        this.noticeService.error(errorMessage);
        this.form.enable();
        this.ngForm.resetForm();
      },
    );
  }

  passwordLettercaseValidate(password: string | null | undefined, typeCheck: string): boolean {
    if (!password) { return false; }

    if ((/[A-Z]/).test(password) && typeCheck == 'uppercase') {
      return true;
    }

    if ((/[a-z]/).test(password) && typeCheck == 'lowercase') {
      return true;
    }

    return false;
  }

  passwordLengthValidate(length: number): boolean {
    return length >= 12;
  }

}
