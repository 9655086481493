import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from '../environments/environment';

import { LoginModule } from './login/login.module';
import { SharedModule } from './shared/shared.module';

import { httpInterceptorProviders } from './shared/interceptors';

import { AppRoutingModule } from './app-routing.module';
import { RootComponent } from './root.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  imports: [
    BrowserModule,

    StoreModule.forRoot([], {
      runtimeChecks: {
        strictActionImmutability: true,
        strictStateImmutability: true,
        // We store dates in state right now, which aren't serializable.
        strictActionSerializability: false,
        strictStateSerializability: false,
      },
    }),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],

    AppRoutingModule,

    SharedModule,
    LoginModule,
    NoopAnimationsModule,

  ],
  providers: [
    ...httpInterceptorProviders,
  ],
  declarations: [RootComponent],
  bootstrap: [RootComponent],
})
export class AppModule {}
