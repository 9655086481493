import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { LoggedInGuard } from './shared/guards';

const ROUTES: Routes = [
  // Login routes are directly imported from LoginModule
  {
    path: '',
    canActivate: [LoggedInGuard],
    canActivateChild: [LoggedInGuard],
    loadChildren: () => import('./main/main.module')
      .then(m => m.MainModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(ROUTES, {
    paramsInheritanceStrategy: 'always',
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload'
})],
  exports: [RouterModule],
})
export class AppRoutingModule { }
