import { formatDate } from '@angular/common';
import {
  AfterViewInit,
  Directive, Input, OnChanges, OnDestroy, SimpleChanges
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({ selector: 'form' })
export class QueryParamsFormDirective implements OnChanges, OnDestroy, AfterViewInit {
  @Input() queryParamsForm?: FormGroup;
  formChangesSub?: Subscription;

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.queryParamsForm) {
      return;
    }

    this.formChangesSub?.unsubscribe();
    const form: FormGroup = changes.queryParamsForm.currentValue;
    this.formChangesSub = form.valueChanges.subscribe(() => {
      this.updateQueryParams();
    });
  }

  ngAfterViewInit(): void {
    if (this.queryParamsForm) {
      this.updateForm(this.queryParamsForm);
    }
  }

  ngOnDestroy(): void {
    this.formChangesSub?.unsubscribe();
  }

  updateQueryParams(): void {
    const searchParams = new URLSearchParams(window.location.search);
    const value: any = this.queryParamsForm?.value || {};
    for (const key in value) {
      if (value[key]) {
        searchParams.set(key, this.serialize(value[key]));
      } else {
        searchParams.delete(key);
      }
    }
    const newRelativePath = `${window.location.pathname}?${searchParams}`;
    history.replaceState(null, '', newRelativePath);
  }

  serialize(value: any): string {
    if (value instanceof Date) {
      return formatDate(value, 'yyyy-MM-dd', 'en-US');
    } else {
      return value.toString();
    }
  }

  updateForm(form: FormGroup): void {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.forEach((value, key) => {
      const control = form.get(key);
      if (control) {
        control.setValue(value);
        control.markAsDirty();
      }
    });
  }

}

