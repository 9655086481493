<header>
  <button type="button" aria-label="Close" (click)="modalRef.close()">
    <span aria-hidden="true">&times;</span>
  </button>
  <h4>{{ options.title }}</h4>
</header>

<main>
  <p>{{ options.message }}</p>
</main>

<footer>
  <button type="button" (click)="modalRef.close()" class="button light-button">
    {{ options.cancelText || 'Cancel' }}
  </button>

  <span></span>

  <button type="button" (click)="modalRef.close(true)" class="button">
    {{ options.okText || 'OK' }}
  </button>
</footer>
