import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { AuthService } from '../services';
import { filterAuthStateNotLoading } from '../utils/auth';

@Injectable({ providedIn: 'root' })
export class LoggedOutGuard  {

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.can();
  }
  canActivateChild(): Observable<boolean | UrlTree> {
    return this.can();
  }

  private can(): Observable<boolean | UrlTree> {
    return this.authService.state$.pipe(
      filterAuthStateNotLoading,
      first(),
      map(state => {
        if (state.type === 'logged-in') {
          return this.router.createUrlTree(['/']);
        }

        return true;
      }),
    );
  }

}
