<ng-container *ngIf="error$ | async as error">
  <ng-container *ngIf="customMessage; else defaultMessage">
    <ng-container *ngTemplateOutlet="customMessage?.templateRef"></ng-container>
  </ng-container>

  <ng-template #defaultMessage>
    <ng-container [ngSwitch]="error.type">
      <span *ngSwitchCase="'required'">{{ label }} is required</span>
      <span *ngSwitchCase="'email'">{{ label }} must be a valid email address.</span>
      <span *ngSwitchCase="'pattern'">Invalid entry</span>
      <span *ngSwitchCase="'maxlength'">{{ label }} is too long.</span>
      <span *ngSwitchCase="'minlength'">{{ label }} is too short.</span>
      <span *ngSwitchCase="'min'">Must be greater than {{ error.error.min }}.</span>
      <span *ngSwitchCase="'max'">Must be less than {{ error.error.max }}.</span>
      <span *ngSwitchCase="'match'">{{ label }} must match.</span>
      <span *ngSwitchDefault>{{ label }} is invalid.</span>
    </ng-container>
  </ng-template>
</ng-container>
