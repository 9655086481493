import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

const DEFAULT_OPTIONS: ToastrOptions = {
  closeButton: false,
  debug: false,
  newestOnTop: false,
  progressBar: false,
  positionClass: 'toast-top-center',
  preventDuplicates: true,
  showDuration: 300,
  hideDuration: 7000,
  timeOut: 1000,
  extendedTimeOut: 1000,
  showEasing: 'swing',
  hideEasing: 'swing',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut',
};

function wrapToastr(method: ToastrDisplayMethod) {
  return function wrapper(message: string, title?: string, overrides?: ToastrOptions): ToastrWrapper {
    const clickObs = new Subject<void>();

    const toast = method(message, title, {
      ...overrides,
      onclick: () => {
        clickObs.next();
        clickObs.complete();
      },
    });

    return {
      toast,
      click$: clickObs.asObservable(),
    };
  };
}

export interface ToastrWrapper {
  click$: Observable<void>;
  toast: JQuery<HTMLElement>;
}

// TODO: Rename this to something like AlertService
@Injectable({ providedIn: 'root' })
export class NoticeService {

  constructor() {
    toastr.options = DEFAULT_OPTIONS;
  }

  readonly info = wrapToastr(toastr.info);
  readonly warning = wrapToastr(toastr.warning);
  readonly success = wrapToastr(toastr.success);
  readonly clear = toastr.clear;
  readonly error = (message: string, error?: Error, title?: string, overrides?: ToastrOptions) => {
    if (error?.message != null) {
      console.error(error.message);
    }
    toastr.error(message, title, overrides);
  };

  remove(toast?: JQuery<HTMLElement>): void {
    // Hack to work around not actually visually removing toasts:
    // https://github.com/CodeSeven/toastr/issues/494
    if (toast) {
      toast.remove();
      toastr.remove(toast);
    } else {
      toastr.remove();
    }
  }

}
