import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import {
  AutoSpinnerComponent, DocumentModalComponent, FormModalComponent, InputErrorComponent,
  LoadingComponent, MessageModalComponent,
} from './components';

import { ErrorMessageDirective, QueryParamsFormDirective } from './directives';

const ANGULAR_MODULES = [
  CommonModule,
  FormsModule,
  HttpClientModule,
  ReactiveFormsModule,
  RouterModule,

  // This needs to be in the root module right now, else errors in lazy-loaded
  // modules.
  OverlayModule,
];

const DECLARATIONS = [
  AutoSpinnerComponent,
  DocumentModalComponent,
  FormModalComponent,
  InputErrorComponent,
  LoadingComponent,
  MessageModalComponent,

  ErrorMessageDirective,
  QueryParamsFormDirective,
];

@NgModule({
  imports: [
    ...ANGULAR_MODULES,
  ],
  exports: [
    ...ANGULAR_MODULES,

    ...DECLARATIONS,
  ],
  declarations: [
    ...DECLARATIONS,
  ]
})
export class SharedModule { }
