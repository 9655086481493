import { OverlayRef } from '@angular/cdk/overlay';

import { Subject } from 'rxjs';

export class ModalRef {

  constructor(
    private readonly overlayRef: OverlayRef,
  ) {}

  private readonly closeSubject = new Subject<any>();

  readonly backdropClick$ = this.overlayRef.backdropClick();
  readonly close$ = this.closeSubject.asObservable();

  close(value?: any): void {
    this.closeSubject.next(value);
    this.closeSubject.complete();
    this.overlayRef.dispose();
  }

}
