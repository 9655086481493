<button type="button" aria-label="Close" (click)="modalRef.close()">
  <span aria-hidden="true">&times;</span>
</button>

<app-loading *ngIf="loading"></app-loading>

<ng-container [ngSwitch]="fileType">
  <div *ngSwitchCase="'pdf'" #pdfContainer class="pdf-container"></div>

  <img *ngSwitchDefault [src]="url" (load)="loading = false">
</ng-container>
