import { Injectable } from "@angular/core";
import {
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse
} from '@angular/common/http';
import { MonoTypeOperatorFunction, Observable, of, range, throwError, zip } from "rxjs";
import { retryWhen, switchMap } from 'rxjs/operators';
 
@Injectable()
export class RetryInterceptor implements HttpInterceptor {
  
  // Retry requests that fail due to lost network connections up to 2 times
  private readonly networkFailure: MonoTypeOperatorFunction<HttpErrorResponse> = 
    errors => zip(errors, range(1,3)).pipe(
      switchMap(([error, i]) => {
        if (error.status !== 0 || i > 2) {
          return throwError(error);
        }
        return of(error);
      }),
    );
    
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      retryWhen(this.networkFailure)
    );
  }
}
