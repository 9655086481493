<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <header>
    <button type="button" aria-label="Close" (click)="modalRef.close()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h4>{{ options.title }}</h4>
  </header>

  <main>
    <p *ngIf="options.message">{{ options.message }}</p>
    <div *ngFor="let field of options.fields" class="form-group">
      <label>
        {{ field.label }}
        <input [type]="field.type" [formControlName]="field.name"
            class="form-control">
        </label>
      <app-input-error [name]="field.name"></app-input-error>
    </div>
  </main>

  <footer>
    <button type="button" (click)="modalRef.close()" class="button light-button">
      Cancel
    </button>

    <span></span>

    <button type="submit" class="button">
      OK
    </button>
  </footer>
</form>
