import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { AuthService } from '../services';
import { filterAuthStateNotLoading } from '../utils/auth';

@Injectable({ providedIn: 'root' })
export class LoggedInGuard  {

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
  ) {}

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.can(state.url);
  }

  canActivateChild(_childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.can(state.url);
  }

  private can(url?: string): Observable<boolean | UrlTree> {
    return this.authService.state$.pipe(
      filterAuthStateNotLoading,
      first(),
      map(state => {
        if (state.type === 'logged-in') {
          return true;
        }

        let queryParams = {};
        if (typeof url === 'string') {
          queryParams = { redirect: url };
        }

        return this.router.createUrlTree(['/login'], { queryParams });
      }),
    );
  }

}
